@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  -webkit-align-items: center;
          align-items: center;
  background: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 1.7rem / 2);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
.react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__week-number--selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__week-number--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}
.react-datepicker__day--holidays .holiday-overlay,
.react-datepicker__month-text--holidays .holiday-overlay,
.react-datepicker__quarter-text--holidays .holiday-overlay,
.react-datepicker__year-text--holidays .holiday-overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: #cf5300;
}
.react-datepicker__day--holidays:hover .holiday-overlay,
.react-datepicker__month-text--holidays:hover .holiday-overlay,
.react-datepicker__quarter-text--holidays:hover .holiday-overlay,
.react-datepicker__year-text--holidays:hover .holiday-overlay {
  visibility: visible;
  opacity: 1;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.react-datepicker__close-icon--disabled {
  cursor: default;
}
.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #ccc;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  -webkit-clip-path: circle(0);
          clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
/* overrides extra padding on button elements in Firefox */
@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }
@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out; }

.introjs-showElement {
  z-index: 9999999 !important; }

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative; }

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative; }

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0); }

.introjs-relativePosition {
  position: relative; }

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .introjs-helperLayer * {
    box-sizing: content-box; }
    .introjs-helperLayer *:before {
      box-sizing: content-box; }
    .introjs-helperLayer *:after {
      box-sizing: content-box; }

.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out; }
  .introjs-tooltipReferenceLayer * {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif; }

.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute; }

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #ffffff; }

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff; }

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff; }

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #ffffff; }

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff; }

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff; }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out; }

.introjs-tooltiptext {
  padding: 20px; }

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px; }

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px; }
  .introjs-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap; }
  .introjs-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #ffffff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  *display: inline; }
  .introjs-button:hover {
    outline: none;
    text-decoration: none;
    border-color: #9e9e9e;
    background-color: #e0e0e0;
    color: #212121; }
  .introjs-button:focus {
    outline: none;
    text-decoration: none;
    background-color: #eeeeee;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
    border: 1px solid #616161;
    color: #212121; }
  .introjs-button:active {
    outline: none;
    text-decoration: none;
    background-color: #e0e0e0;
    border-color: #9e9e9e;
    color: #212121; }
  .introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.introjs-skipbutton {
  box-sizing: content-box;
  color: #616161;
  float: right;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 7px 10px; }
  .introjs-skipbutton:hover, .introjs-skipbutton:focus {
    color: #212121;
    outline: none;
    text-decoration: none; }

.introjs-prevbutton {
  float: left; }

.introjs-nextbutton {
  float: right; }

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none; }
  .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9e9e9e;
    border-color: #bdbdbd;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }

.introjs-hidden {
  display: none; }

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }
  .introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block; }
    .introjs-bullets ul li {
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px; }
      .introjs-bullets ul li a {
        transition: width 0.1s ease-in;
        box-sizing: content-box;
        display: block;
        width: 6px;
        height: 6px;
        background: #ccc;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer; }
        .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
          width: 15px;
          background: #999;
          text-decoration: none;
          outline: none; }
      .introjs-bullets ul li a.active {
        width: 15px;
        background: #999; }

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0; }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c; }

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%; }

.introjs-fixedTooltip {
  position: fixed; }

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer; }
  .introjs-hint:focus {
    border: 0;
    outline: 0; }
  .introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57); }

.introjs-hidehint {
  display: none; }

.introjs-fixedhint {
  position: fixed; }

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out; }

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
          animation: none; }

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
          animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0; }

